<template>
  <div class="set-point">
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <span>保存成功</span>
    </el-dialog>
    
    <div class="multi-form">
      <p>全局参数设置</p>
        
        <!-- 模态分析测点选择 -->
      <section>
        <span class="title">模态分析测点选择</span>
    
        <el-form :model="formData4" :inline="true"  :rules="rules4" ref="formData4">
    
           <el-form-item label="时滞" prop="num1" class="itemForm"  >
                <el-input v-model="formData4.num1" placeholder="请输入"  clearable/>
                <span class="unit">秒</span>
          </el-form-item>

           <el-form-item label="最小阶次" prop="num2" class="itemForm"  >
                <el-input v-model="formData4.num2" placeholder="请输入"  clearable/>
          </el-form-item>

           <el-form-item label="最大阶次" prop="num3" class="itemForm"  >
                <el-input v-model="formData4.num3" placeholder="请输入"  clearable/>
          </el-form-item>

          <el-form-item label="时间间隔" prop="num4" class="itemForm"  >
            <el-select v-model="formData4.num4" placeholder="请选择" @change="changeNumber44"  >
              <el-option label="10天" value="1" />
              <el-option label="20天" value="2"/>
              <el-option label="30天" value="3"/>
            </el-select>
          </el-form-item>
                          <!-- format="HH:mm:ss"
                   value-format= "HH:mm:ss"  -->

          <el-form-item label="分析时段" prop="num5" class="itemForm" >
                  <el-time-picker
                     @change="time"
                      v-model="formData4.num5"
                     placeholder="选择时间"
                      >
                  </el-time-picker>
                  <span>-</span>
                  <el-time-picker
                     @change="time1"
                    v-model="formData4.num6"
                    disabled
                    placeholder="">
                  </el-time-picker>
          </el-form-item>


        </el-form>
      </section>



    </div>
  
    <div class="btns">
      <el-button v-if="state ==1" @click="saveData" >保存</el-button>
      <el-button v-if="state ==2" class="hui">保存</el-button>
      <!-- <el-button @click="back">返回</el-button> -->
    </div>
  </div>
</template>

<script>
export default {

  
  data() {
      //密码手机号
            var minData=(rule, value,callback)=>{
              let reg = /^[1-9]([0-9])*$/;
              let flag = reg.test(value);

                if(!flag){
                   callback(new Error('需为正整数'))
                }
                 if (!value){
                      callback(new Error('请输入！'))
                }
                 else if (Number(this.formData4.num2) > 10 || Number(this.formData4.num2) < 1){
                      callback(new Error('请输入1-10'))
                }
                else if (Number(this.formData4.num2) > Number(this.formData4.num3)){
                      callback(new Error('最小阶次不能大于最大阶次！'))
                }      
                else {
 
                      callback()
                }
            };
            var maxData=(rule, value,callback)=>{
               let reg = /^[1-9]([0-9])*$/;
               let flag = reg.test(value);

                if(!flag){
                   callback(new Error('必须为正整数'))
                }
                if (!value){
                      callback(new Error('请输入！'))
                }
                 else if (Number(this.formData4.num3) > 10 || Number(this.formData4.num3) < 1){
                      callback(new Error('请输入1-10'))
                }  
                else if (Number(this.formData4.num2) > Number(this.formData4.num3)){
                      callback(new Error('最大阶次不能小于最小阶次！'))
                }     
                else {
 
                      callback()
                }
            };
            //正整数
            var zheng=(rule, value,callback)=>{
              let reg = /^[1-9]([0-9])*$/;
              let flag = reg.test(value);

                if(!flag){
                   callback(new Error('需为正整数'))
                }
                else if (!value){
                      callback(new Error('请输入！'))
                }     
                else {
                      callback()
                }
            };
            //时间必须是数字
            var checkedSerialNumber = (rule, value, callback) => {
              if (value) {
                if (!Number(value)) callback("请输入数字");
              } else {
                callback();
              }
            };



    return {
        // dialogVisible:'',
        num5Data: true,
        projectId:'',
        startTime:'',
        detime:'',
        quiet: '0',
        readonly: true, //只读
        state:'',
        msgList:[
        {id:1,title:'测点编号',icon:'#iconbianhao',value:'SL070214'},
        {id:2,title:'监测类型',icon:'#iconshebeiguanli',value:'环境类'},
        {id:3,title:'监测指标',icon:'#iconzhibiao',value:'温度'},
        {id:4,title:'数据类型',icon:'#iconshuju1',value:'静态'},
        {id:5,title:'位置描述',icon:'#iconmiaoshu',value:'桥梁中部，第一根桥梁柱'},
        {id:6,title:'传感器型号',icon:'#iconqunfengshebeixinghao',value:'Rn100'},
        {id:7,title:'系数',icon:'#iconjuleixishu',value:'0.95'},
        {id:8,title:'单位',icon:'#iconwenduji',value:'℃'},
        {id:9,title:'精度',icon:'#iconslamjingdu-gongzuojuli',value:'0.5'},
        {id:10,title:'通讯地址',icon:'#icondianhua',value:'5216482'},
      ],
      form1: {},
      form2: {},
      //时域统计
      formData1: {
        status:'',
        number:'1小时',
        num1:'',
        num2:'',
      },

      //准静态时域统计
       formData5: {
        status:'',
        number:'1小时',
        num1:'',
        num2:'',
      },



      


      formData4: {
        status:'0',
        num1:'',
        num2:'',
        num3:'',
        num4:'',
        num5:'',
        num6:'',
      },

       rules4: {

          num1: [
            { required: true, message: '请输入', trigger: 'blur' },
             { validator: zheng, trigger: "blur" }
          ],
            num2: [
            { required: true, message: '请输入', trigger: 'blur' },
            { trigger: 'blur', validator: minData}
          ],
            num3: [
            { required: true, message: '请输入', trigger: 'blur' },
             { trigger: 'blur', validator: maxData}
          ],
          num4:[
             { required: true, message: '请选择', trigger: 'change' }
          ],
          num5: [
            { required: true, message: '请输入', trigger: 'blur' },
          ],
        }, 




      methodOptions: [
        {id: 1,name: '矩形窗', value:'0'},
        {id: 2,name: '低通滤波', value:'0'},
        {id: 3,name: '陷波滤波', value:'0'}
      ],
      show1: false,
      show2: false,
      show3: false,


      //动静分离
       formData5:{
         status:'',
         num1:'',
         num2:'',
      },

      
      rules5: {
          num2: [
            { required: true, message: '请输入', trigger: 'blur' },
          ],
        }, 



      formData: {
        status:'',
      },
      typeOptions: [
        {id:1,name: '全部'},
        {id:2,name: '荷载类'},
        {id:3,name: '环境类'},
        {id:4,name: '效应类'},
        {id:5,name: '衍生指标类'}
      ],
      targetOptions: [
        {id:1,name: '全部'},
        {id:2,name: '车辆荷载'},
        {id:3,name: '船舶及冰凌撞击荷载'},
        {id:4,name: '风荷载'},
        {id:5,name: '地震动监测'},
        {id:6,name: '其他'}
      ],
      selectedArr: [],
 
      // id: this.$route.params.id || sessionStorage.getItem('handleId'),
      targetId: this.$route.params.targetId || sessionStorage.getItem('analysisId'), //传感器id
    
      
    }
  },
  created () {
    this.state = sessionStorage.getItem('isAdmin');
    this.projectId = sessionStorage.getItem('projectId')
    // console.log('id', this.projectId)


  },
  mounted () {
    this.details2();

  },
  methods: {
    time(val){
      // console.log(val)
      let time = new Date(val)
      this.formData4.num6 = new Date(time.setTime(time.getTime() + (30 * 60 * 1000))).Format('yyyy-MM-dd hh:mm:ss')
      // console.log(this.formData4.num6)

      var arr =  new Date(val.setTime(val.getTime())).Format('yyyy-MM-dd hh:mm:ss')      // 2021-3-23 18:50:12

      var arr1=(arr).split(" ");
      this.startTime = arr1[1];
      // console.log('this.startTime', this.startTime); // 10:10:10
      this.num5Data = false;
    },
    time1(){
        // console.log(this.formData4.num6)
    },




    back(){
      this.$router.push('/analysis')

    },

  

    //详情主题   //配置管理   数据管理设置，根据id查询实例
    details2(){
      this.$axios.get(`${this.baseURL}setttings/settingsDataOverall/${this.projectId}`).then(res => {
        var data = res.data.data;
        // console.log('详情2',data);


         this.formData4.num1 =  data.modalTimeLag;
         this.formData4.num2 =  data.modalNMin;
         this.formData4.num3 =  data.modalNMax;
        
        this.formData4.num4 =(data.modalPeriod).toString()

        this.startTime = data.modalDuration;
         let num5 = '2020-10-20 ' + data.modalDuration
         this.formData4.num5 = num5;
         this.formData4.num6 = new Date(new Date(num5).getTime() + (30 * 60 * 1000)).Format('yyyy-MM-dd hh:mm:ss');

        
      })
    },
  saveData(){
    this.saveData1();   //动态应用    
  },

    saveData1(){

      const p4=new Promise((resolve,reject)=>{
        this.$refs['formData4'].validate((valid) => {
                if(valid) resolve()
            })
      })


      Promise.all([p4]).then(()=>{
            if(this.formData4.status == 1){
              if(Number(this.formData4.num2) >= Number(this.formData4.num3)){
                this.$message.error('最小阶次不能大于或等于最大阶次');
                return
              }
            }
          this.updated1();
        })

    },

    updated1(){
    
  
      var param = new URLSearchParams();
  
      param.append("modalTimeLag",this.formData4.num1);       //时滞
      param.append("modalNMin",this.formData4.num2);         //模态分析
      param.append("modalNMax",this.formData4.num3);          //模态分析
      param.append("modalPeriod",this.formData4.num4);    //分析间隔
      param.append("modalDurationTime", this.startTime);    //分析时段

      // console.log('11', this.formData4.num3)


      for (let p of param) {  //查看
        // console.log('查看', p);
       
      }

      this.$axios.post(`${this.baseURL}setttings/settingsDataOverall/${this.projectId}`, param).then(res => {
        // console.log('提交更新',res);
         if(res.data.status == '200'){
            // this.dialogVisible = true;
            // setTimeout(()=>{
            //   this.dialogVisible = false;
            // },1000)
            this.$message.success('保存成功');

          }
      })




    },


    //是否1
    changeNumber1(){
      console.log(this.formData1.status)
    },
    changeNumber2(){
       console.log(this.formData2.number)
    },
     changeNumber3(){
       console.log(this.formData3.number)
    },
      changeNumber4(){
       console.log(this.formData4.status)
    },
    changeNumber44(){
      console.log(this.formData4.num4)
    },


    //消噪处理方式
    changeArr(){
      console.log( this.formData3.selectedArr)
      this.checkboxSelect();
    },

    //消噪方法是否显示
    checkboxSelect(){
      this.formData3.selectedArr.forEach((item, i)=>{
                if(item == 1){
                    this.show1 = true;
                    return true;
                }else{
                    this.show1 = false;
                }
        })
   

    },



   
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__error{
  top: 42px;
}

@media screen and (min-width:1650px) {
  .set-point::v-deep {
    .el-input,
    .el-input__inner {
      width: 120px;   //178
    }
    .el-form-item__label {
      width: 117px;
    }
    .multi-form .el-form {
      // padding: 0 1% 0 6.47%;
    }
    .analyse .el-form {
      .el-form-item:first-child {
        .el-form-item__content {
          width: 198px;
        }
      }
      .el-form-item:nth-child(2) {
        margin-right: 100px;
      }
      .el-form-item__label {
        width: 100px;
      }
    }
    .rehandle {
      .el-form-item {
        margin-right: 2%;
      }
      .el-form-item:not(:first-child) {
        .el-form-item__label{
          width: 70px;
        }
      }
    }
    .limit-value {
      .el-form-item {
        width: 258px;
        .el-input,
        .el-input__inner {
          width: 130px;
        }
      }
    }
  }
}
@media screen and (max-width:1650px) {
  .set-point::v-deep {
    .el-input,
    .el-input__inner {
      width: 127px;
    }
    .el-form:first-child,
    .derive {
      .el-form-item__label {
        width: 110px;
      }
    }
    .multi-form .el-form {
      padding-left: 20px;
    }
    .analyse section {
      .el-form-item:first-child {
        .el-form-item__content {
          width: 127px;
        }
      }
      .el-form-item:nth-child(2) {
        margin: 0 80px 0 15px;
      }
      .el-form-item__label {
        width: 60px;
      }
    }
    .rehandle {
      .el-form-item:not(:first-child) {
        .el-form-item__label{
          width: 72px;
        }
      }
      .el-form-item {
        margin-right: 20px !important;
      }
    }
    .limit-value {
      .el-form-item {
        width: 220px;
        .el-form-item__label {
          margin-right: 10px !important;
        }
        .el-input,
        .el-input__inner {
          width: 115px;
        }
      }
      .el-form-item:nth-child(odd) {
        width: 200px;
        .el-form-item__label {
          width: 70px !important;
        }
      }
    }
  }
  .derive::v-deep {
    .el-input,
    .el-input__inner {
      width: 183px;
    }
  }
}
  .el-form::v-deep {
    .el-form-item__label {
      padding: 0;
      height: 60px;
      line-height: 60px;
      text-align: center;
      background: #0c265a;
    }
    .el-form-item__content {
      height: 60px;
      width: auto;
      line-height: 60px;
      text-align: center;
    }
    .el-input__inner {
      padding: 0;
      height: 30px;
      line-height: 30px;
      text-align: center;
    }
    .el-select {
      .el-input__inner {
        text-align: left;
        padding-left: 15px;
        border: 1px solid rgba(151, 151, 151, 0.29);
      }
      .el-input__icon {
        height: 30px;
        line-height: 30px;
      }
    }
    .el-input.is-disabled .el-input__inner {
      border: 1px solid #383E41 !important;
      background: rgba(56, 63, 65, 0.29) !important;
    }
  }
  .long-input::v-deep {
    .el-input__inner {
      width: 90%;
    }
  }
  p {
    color: #DBF804;
    line-height: 40px;
    text-align: center;
  }
  .el-form-item {
    margin: 0;
    border-top: 1px solid #5e5e5f2e;
  }
  .el-form-item {
    display: inline-flex;
    &::v-deep {
      .el-form-item__content {
        flex: 1;
      }
    }
  }
  .title {
    width: 148px;
    display: flex;
    word-wrap: none;
    white-space: nowrap;
    color: #DBF804;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #5e5e5f2e;
    background: #0c265a;
  }
  .derive {
    &::v-deep {
      .el-form-item__label {
        // margin-right: 22px;
      }
    }
  }
  .multi-form,
  .el-form:first-of-type,
  .el-form:nth-of-type(2) {
    margin: 4px;
    border-radius: 8px;
    margin-bottom: 26px;
    border: 1px solid #5e5e5f2e;
    box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.5);
  }
  .el-form:first-child {
    &::v-deep .el-form-item__content > div:not(.el-select) {
      .el-input__inner {
        padding: 0;
        border: none;
      }
    }
    .el-form-item {
      width: 25%;
    }
    .el-form-item:nth-of-type(5) {
      width: 100%;
    }
    .el-form-item:nth-last-of-type(2) {
      width: 50%;
    }
    .el-form-item:nth-of-type(n+6) {
      border-bottom: 1px solid #5e5e5f2e;
    }
    .el-form-item:nth-of-type(n+10) {
      border: none;
    }
  }
  .el-form:nth-child(2) {
    .el-form-item {
      display: block;
      &::v-deep {
        .el-form-item__content {
          display: inline-flex;
        }
      }
      .el-select:not(:last-child) {
        // margin-right: 22px;
      }
    }
  }
  .multi-form {
    section {
      display: flex;
      border-top: 1px solid #5e5e5f2e;
      p {
        width: 117px;
        height: 60px;
        line-height: 60px;
        background: #0c265a;
      }
      .el-form {
        flex: 1;
        margin: 0;
        border: none;
        display: flex;
        flex-wrap: wrap;
        box-shadow: none;
        .el-form-item {
          border: none;
          height: 60px;
          &::v-deep {
            .el-form-item__label {
              text-align: left;
              margin-right: 20px;
              white-space: nowrap;
              background: transparent;
            }
          }
          .unit {
            padding: 0 12px;
            white-space: nowrap;
          }
        }
      }
    }
    .denosing-form {
      .el-form {
        margin: 0;
        border-radius: 0;
        border-bottom: 1px solid #5e5e5f2e;
      }
      .el-form-item:nth-of-type(2) {
        width: 50%;
        &::v-deep {
          .el-form-item__content {
            white-space: nowrap;
          }
        }
      }
    }
    .rehandle {
      .el-form-item:first-child {
        &::v-deep .el-form-item__label{
          width: 182px;
        }
      }
    }
  }
  .multi-form:nth-of-type(-n+1) {
    .el-form-item {
      margin-right: 4%;
    }
  }
  .set-time {
    padding: 0 2% !important;
  }
  .set-threshold {
    .title {
      border: none;
    }
    .form-title {
      margin: 20px 0;
      span {
        width: 50%;
        display: inline-block;
      }
    }
    .form-list {
      flex: 1;
      padding: 0 0 23px 2%;
      .el-form {
        padding: 0;
        &::v-deep {
          .el-form-item__content {
            width: 78px;
          }
          .el-form-item__label {
            padding: 0;
            width: 90px;
          }
        }
      }
      .limit-value {
        width: 50%;
        .el-form-item {
          display: inline-flex;
        }
      }
    }
  }
  .btns {
    margin-top: 44px;
    text-align: center;
    .el-button {
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    }
    // .el-button:last-child {
    //   margin-left: 50px;
    //   background: #1E2529;
    // }
  }

  .div{
    margin-bottom: 20px;
    ::v-deep .el-form{
      .ml20{
        margin-left: 20px;
      }
      .el-form-item__label{
        font-size: 16px;
        font-weight: 500;
        color:#fff;
        text-align: left;
        line-height: 30px;
        width: 102px;
        padding:0;
      }
      .el-form-item{
        margin-bottom: 0;
        display: flex;
        &.mB20{
          margin-bottom: 20px;
        }
        .el-form-item__content{
          margin-left: 0 !important;
          line-height: 30px;
        }
      }
      .line{
        padding: 0 10px;
      }
      .el-input{
        width: 134px;
        height: 30px;
        .el-input__inner{
          font-size: 16px;
          color:#fff;
        }
        .el-select__caret{
          line-height: 30px;
        }
        &.el-date-editor{
          width: 162px;
          .el-input__inner{
            padding:0 0 0 8px;
            font-size: 14px;
          }
          .el-input__prefix{
            display: none;
          }
        }

      }
      .el-button.ml24{margin-left: 24px;}
    }
    .title{margin:14px 0 8px 0;}
    .msgList{
      li{
        height: 48px;
        line-height: 48px;
        margin-top: 4px;
        .labelTxt{
          padding-left: 20px;
          background: rgba(48, 56, 58, 0.69);
          width: 131px;
          span{padding-left: 4px;}
        }
        .contentTxt{
          font-weight: 500;
          padding-left: 16px;
          width: 134px;
          background: rgba(34,39,40, 0.41);
        }
        &:nth-child(5){
          flex:1;
          .contentTxt{
            flex: 1;
            max-width: 399px;
          }
        }
      }
    }
    .myChart{
      height: 200px;
    }
    .el-checkbox{
      margin-left: 38px;
      margin-right: 0;
    }
    .toolTipList{
      li{
        margin-left: 14px;
        &:first-child{
          margin-left: 0;
        }
        .lineColor{
          width:39px;
          height: 1px;
          //background-color: #8AE8FF;
        }
        .pl10{
          padding-left: 8px;
        }
      }
    }
  }
  //@media screen and (max-width:1540px) {
  @media screen and (max-width:1848px) {
    .main{
      ::v-deep .el-form{
        .el-form-item__label {
          font-size:14px;
          width: 94px;
        }
        .ml20{
          margin-left: 0px;
        }
        .el-input{
          width: 120px;
          height: 30px;
          .el-input__inner{
            font-size: 14px;
          }

        }
        button.el-button{
          width: 90px;
          height: 30px;
          flex-shrink: 0;
          &.ml24{margin-left: 10px;}
        }
      }
    }

  }

//

  @media screen and (min-width:1650px) {
  .set-point::v-deep {
    .el-input,
    .el-input__inner {
      width: 120px;
    }
    .el-form-item__label {
      width: 117px;
    }
    .multi-form .el-form {
      // padding: 0 1% 0 6.47%;
    }
    .analyse .el-form {
      .el-form-item:first-child {
        .el-form-item__content {
          width: 198px;
        }
      }
      .el-form-item:nth-child(2) {
        margin-right: 100px;
      }
      .el-form-item__label {
        width: 100px;
      }
    }
    .rehandle {
      .el-form-item {
        margin-right: 2%;
      }
      .el-form-item:not(:first-child) {
        .el-form-item__label{
          width: 70px;
        }
      }
    }
    .limit-value {
      .el-form-item {
        width: 258px;
        .el-input,
        .el-input__inner {
          width: 130px;
        }
      }
    }
  }
}
@media screen and (max-width:1650px) {
  .set-point::v-deep {
    .el-input,
    .el-input__inner {
      width: 127px;
    }
    .el-form:first-child,
    .derive {
      .el-form-item__label {
        width: 110px;
      }
    }
    .multi-form .el-form {
      padding-left: 20px;
    }
    .analyse section {
      .el-form-item:first-child {
        .el-form-item__content {
          width: 127px;
        }
      }
      .el-form-item:nth-child(2) {
        margin: 0 80px 0 15px;
      }
      .el-form-item__label {
        width: 60px;
      }
    }
    .rehandle {
      .el-form-item:not(:first-child) {
        .el-form-item__label{
          width: 72px;
        }
      }
      .el-form-item {
        margin-right: 20px !important;
      }
    }
    .limit-value {
      .el-form-item {
        width: 220px;
        .el-form-item__label {
          margin-right: 10px !important;
        }
        .el-input,
        .el-input__inner {
          width: 115px;
        }
      }
      .el-form-item:nth-child(odd) {
        width: 200px;
        .el-form-item__label {
          width: 70px !important;
        }
      }
    }
  }
  .derive::v-deep {
    .el-input,
    .el-input__inner {
      width: 183px;
    }
  }
}
  .el-form::v-deep {
    .el-form-item__label {
      padding: 0;
      height: 60px;
      line-height: 60px;
      text-align: center;
      background: #0c265a;
    }
    .el-form-item__content {
      height: 60px;
      width: auto;
      line-height: 60px;
      text-align: center;
    }
    .el-input__inner {
      padding: 0;
      height: 30px;
      line-height: 30px;
      text-align: center;
    }
    .el-select {
      .el-input__inner {
        text-align: left;
        padding-left: 15px;
        border: 1px solid rgba(151, 151, 151, 0.29);
      }
      .el-input__icon {
        height: 30px;
        line-height: 30px;
      }
    }
    .el-input.is-disabled .el-input__inner {
      border: 1px solid #383E41 !important;
      background: rgba(56, 63, 65, 0.29) !important;
    }
  }
  .long-input::v-deep {
    .el-input__inner {
      width: 90%;
    }
  }
  p {
    color: #DBF804;
    line-height: 40px;
    text-align: center;
  }
  .el-form-item {
    margin: 0;
    border-top: 1px solid #5e5e5f2e;
  }
  .el-form-item {
    display: inline-flex;
    &::v-deep {
      .el-form-item__content {
        flex: 1;
      }
    }
  }
  .title {
    width: 148px;
    display: flex;
    word-wrap: none;
    white-space: nowrap;
    color: #DBF804;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #5e5e5f2e;
    background: #0c265a;
  }
  .derive {
    &::v-deep {
      .el-form-item__label {
        // margin-right: 22px;
      }
    }
  }
  .multi-form,
  .el-form:first-of-type,
  .el-form:nth-of-type(2) {
    margin: 4px;
    border-radius: 8px;
    margin-bottom: 26px;
    border: 1px solid #5e5e5f2e;
    box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.5);
  }
  .el-form:first-child {
    &::v-deep .el-form-item__content > div:not(.el-select) {
      .el-input__inner {
        padding: 0;
        border: none;
      }
    }
    .el-form-item {
      width: 25%;
    }
    .el-form-item:nth-of-type(5) {
      width: 100%;
    }
    .el-form-item:nth-last-of-type(2) {
      width: 50%;
    }
    .el-form-item:nth-of-type(n+6) {
      border-bottom: 1px solid #5e5e5f2e;
    }
    .el-form-item:nth-of-type(n+10) {
      border: none;
    }
  }
  .el-form:nth-child(2) {
    .el-form-item {
      display: block;
      &::v-deep {
        .el-form-item__content {
          display: inline-flex;
        }
      }
      .el-select:not(:last-child) {
        // margin-right: 22px;
      }
    }
  }
  .multi-form {
    section {
      display: flex;
      border-top: 1px solid #5e5e5f2e;
      p {
        width: 117px;
        height: 60px;
        line-height: 60px;
        background: #0c265a;
      }
      .el-form {
        flex: 1;
        margin: 0;
        border: none;
        display: flex;
        flex-wrap: wrap;
        box-shadow: none;
        .el-form-item {
          border: none;
          height: 60px;
          &::v-deep {
            .el-form-item__label {
              text-align: left;
              margin-right: 20px;
              white-space: nowrap;
              background: transparent;
            }
          }
          .unit {
            padding: 0 12px;
            white-space: nowrap;
          }
        }
      }
    }
    .denosing-form {
      .el-form {
        margin: 0;
        border-radius: 0;
        border-bottom: 1px solid #5e5e5f2e;
      }
      .el-form-item:nth-of-type(2) {
        width: 50%;
        &::v-deep {
          .el-form-item__content {
            white-space: nowrap;
          }
        }
      }
    }
    .rehandle {
      .el-form-item:first-child {
        &::v-deep .el-form-item__label{
          width: 182px;
        }
      }
    }
  }
  .multi-form:nth-of-type(-n+1) {
    .el-form-item {
      margin-right: 4%;
    }
  }
  .set-time {
    padding: 0 2% !important;
  }
  .set-threshold {
    .title {
      border: none;
    }
    .form-title {
      margin: 20px 0;
      span {
        width: 50%;
        display: inline-block;
      }
    }
    .form-list {
      flex: 1;
      padding: 0 0 23px 2%;
      .el-form {
        padding: 0;
        &::v-deep {
          .el-form-item__content {
            width: 78px;
          }
          .el-form-item__label {
            padding: 0;
            width: 90px;
          }
        }
      }
      .limit-value {
        width: 50%;
        .el-form-item {
          display: inline-flex;
        }
      }
    }
  }
  .btns {
    margin-top: 44px;
    text-align: center;
    .el-button {
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    }
    // .el-button:last-child {
    //   margin-left: 50px;
    //   background: #1E2529;
    // }
  }


.marginL10{
  margin-left: 10px !important;
}
.marginR10{
  margin-right: 10px !important;
}

::v-deep .itemForm label{
  // padding-left: 40px !important;
}

//浏览版 按钮样式
.hui{
     cursor: not-allowed;
      background: #1E2529 !important;
}
.hui:hover{
      cursor: not-allowed;
      background: #1E2529 !important;
}

::v-deep .el-form-item__label{
  text-align: center !important;
}

::v-deep div .el-message{
  min-width: 350px !important;
}

::v-deep .el-date-editor .el-date-editor {
  width: 115px  !important;
}

::v-deep .el-dialog{
  width: 250px !important;
  .el-dialog__body{
    color: #fff;
    font-size: 18px;
    text-align: center;
  }
}



</style>